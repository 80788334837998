define("discourse/plugins/chat/discourse/components/styleguide/chat-modal-move-message-to-channel", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/owner", "@ember/service", "discourse/plugins/chat/discourse/components/chat/modal/move-message-to-channel", "discourse/plugins/chat/discourse/lib/fabricators", "@ember/template-factory"], function (_exports, _component, _component2, _object, _owner, _service, _moveMessageToChannel, _fabricators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<Chat::Modal::MoveMessageToChannel>">
    <Styleguide::Controls::Row>
      <DButton @translatedLabel="Open modal" @action={{this.openModal}} />
    </Styleguide::Controls::Row>
  </StyleguideExample>
  */
  {
    "id": "ATPl85dR",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<Chat::Modal::MoveMessageToChannel>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@translatedLabel\",\"@action\"],[\"Open modal\",[30,0,[\"openModal\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/controls/row\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-modal-move-message-to-channel.hbs",
    "isStrictMode": false
  });
  class ChatStyleguideChatModalMoveMessageToChannel extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    channel = (() => new _fabricators.default((0, _owner.getOwner)(this)).channel())();
    selectedMessageIds = (() => [new _fabricators.default((0, _owner.getOwner)(this)).message({
      channel: this.channel
    })].mapBy("id"))();
    openModal() {
      return this.modal.show(_moveMessageToChannel.default, {
        model: {
          sourceChannel: this.channel,
          selectedMessageIds: [new _fabricators.default((0, _owner.getOwner)(this)).message({
            channel: this.channel
          })].mapBy("id")
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "openModal", [_object.action]))();
  }
  _exports.default = ChatStyleguideChatModalMoveMessageToChannel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatModalMoveMessageToChannel);
});