define("discourse/plugins/chat/discourse/lib/scroll-helpers", ["exports", "@ember/runloop", "discourse/plugins/chat/discourse/lib/chat-ios-hacks"], function (_exports, _runloop, _chatIosHacks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollListToBottom = scrollListToBottom;
  _exports.scrollListToMessage = scrollListToMessage;
  _exports.scrollListToTop = scrollListToTop;
  async function scrollListToBottom(list) {
    await new Promise(resolve => {
      (0, _chatIosHacks.stackingContextFix)(list, () => {
        list.scrollTo({
          top: 0,
          behavior: "auto"
        });
        (0, _runloop.next)(resolve);
      });
    });
  }
  async function scrollListToTop(list) {
    await new Promise(resolve => {
      (0, _chatIosHacks.stackingContextFix)(list, () => {
        list.scrollTo({
          top: -list.scrollHeight,
          behavior: "auto"
        });
        (0, _runloop.next)(resolve);
      });
    });
  }
  function scrollListToMessage(list, message) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      highlight: false,
      position: "start",
      autoExpand: false
    };
    if (!message) {
      return;
    }
    if (message?.deletedAt && opts.autoExpand) {
      message.expanded = true;
    }
    (0, _runloop.schedule)("afterRender", () => {
      const messageEl = list.querySelector(`.chat-message-container[data-id='${message.id}']`);
      if (!messageEl) {
        return;
      }
      if (opts.highlight) {
        message.highlight();
      }
      (0, _chatIosHacks.stackingContextFix)(list, () => {
        messageEl.scrollIntoView({
          behavior: "auto",
          block: opts.position || "center"
        });
      });
    });
  }
});