define("discourse/plugins/chat/discourse/routes/chat-channels", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    activate() {
      this.chat.activeChannel = null;
    }
    beforeModel() {
      const id = this.currentUser.custom_fields.last_chat_channel_id;
      if (this.site.desktopView) {
        if (id) {
          this.chatChannelsManager.find(id).then(c => {
            return this.router.replaceWith("chat.channel", ...c.routeModels);
          });
        } else {
          this.router.replaceWith("chat.browse.open");
        }
      }
    }
    model() {
      return this.chatChannelsManager.publicMessageChannels;
    }
  }
  _exports.default = ChatChannelsRoute;
});