define("discourse/plugins/chat/discourse/models/chat-notice", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNotice {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatNotice(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "channelId", [_tracking.tracked]))();
    #channelId = (() => (dt7948.i(this, "channelId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "textContent", [_tracking.tracked]))();
    #textContent = (() => (dt7948.i(this, "textContent"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.channelId = args.channel_id;
      this.textContent = args.text_content;
      this.type = args.notice_type;
      this.data = args.data;
    }
  }
  _exports.default = ChatNotice;
});