define("discourse/plugins/chat/discourse/controllers/chat-channel", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/service"], function (_exports, _tracking, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "targetMessageId", [_tracking.tracked], function () {
      return null;
    }))();
    #targetMessageId = (() => (dt7948.i(this, "targetMessageId"), void 0))();
    // Backwards-compatibility
    queryParams = ["messageId"];
  }
  _exports.default = ChatChannelController;
});