define("discourse/plugins/chat/discourse/routes/chat-channel-thread-near-message", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This route is only here as a convenience method for a clean `/c/:channelTitle/:channelId/t/:threadId/:messageId` URL.
  // It's not a real route, it just redirects to the real route after setting a param on the controller.
  class ChatChannelThreadNearMessage extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel() {
      const thread = this.modelFor("chat-channel-thread");
      const {
        messageId
      } = this.paramsFor(this.routeName);
      if (messageId || this.controllerFor("chat-channel-thread").get("targetMessageId")) {
        this.controllerFor("chat-channel-thread").set("targetMessageId", messageId);
      }
      this.router.replaceWith("chat.channel.thread", ...thread.routeModels);
    }
  }
  _exports.default = ChatChannelThreadNearMessage;
});