define("discourse/plugins/chat/discourse/routes/chat-channel", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/chat/discourse/routes/chat-channel-decorator"], function (_exports, _service, _discourse, _chatChannelDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatChannelRoute = dt7948.c(class ChatChannelRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    redirect(model) {
      if (this.site.mobileView) {
        return;
      }
      const messageId = this.paramsFor("chat.channel.near-message").messageId;
      const threadId = this.paramsFor("chat.channel.thread").threadId;
      if (model.threadingEnabled && !messageId && !threadId && model.threadsManager.unreadThreadCount > 0) {
        this.router.transitionTo("chat.channel.threads", ...model.routeModels);
      }
    }
  }, [_chatChannelDecorator.default]);
  var _default = _exports.default = ChatChannelRoute;
});