define("discourse/plugins/chat/discourse/components/chat-message-collapser", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template", "discourse/lib/utilities", "discourse-common/lib/dom-from-string", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _service, _template, _utilities, _domFromString, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isCollapsible = isCollapsible;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-message-collapser">
    {{#if this.hasUploads}}
      {{html-safe @cooked}}
  
      <Collapser @header={{this.uploadsHeader}} @onToggle={{@onToggleCollapse}}>
        <div class="chat-uploads">
          {{#each @uploads as |upload|}}
            <ChatUpload @upload={{upload}} />
          {{/each}}
        </div>
      </Collapser>
    {{else}}
      {{#each this.cookedBodies as |cooked|}}
        {{#if cooked.needsCollapser}}
          <Collapser @header={{cooked.header}} @onToggle={{@onToggleCollapse}}>
            {{#if cooked.videoAttributes}}
              <div class="chat-message-collapser-lazy-video">
                <LazyVideo @videoAttributes={{cooked.videoAttributes}} />
              </div>
            {{else}}
              {{cooked.body}}
            {{/if}}
          </Collapser>
        {{else}}
          {{cooked.body}}
        {{/if}}
      {{/each}}
    {{/if}}
  </div>
  */
  {
    "id": "0kb4UYGW",
    "block": "[[[10,0],[14,0,\"chat-message-collapser\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasUploads\"]],[[[1,\"    \"],[1,[28,[35,1],[[30,1]],null]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@header\",\"@onToggle\"],[[30,0,[\"uploadsHeader\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"chat-uploads\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"          \"],[8,[39,5],null,[[\"@upload\"],[[30,4]]],null],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"cookedBodies\"]]],null]],null],null,[[[41,[30,5,[\"needsCollapser\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@header\",\"@onToggle\"],[[30,5,[\"header\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,5,[\"videoAttributes\"]],[[[1,\"            \"],[10,0],[14,0,\"chat-message-collapser-lazy-video\"],[12],[1,\"\\n              \"],[8,[39,6],null,[[\"@videoAttributes\"],[[30,5,[\"videoAttributes\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,5,[\"body\"]]],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,5,[\"body\"]]],[1,\"\\n\"]],[]]]],[5]],null]],[]]],[13]],[\"@cooked\",\"@onToggleCollapse\",\"@uploads\",\"upload\",\"cooked\"],false,[\"if\",\"html-safe\",\"collapser\",\"each\",\"-track-array\",\"chat-upload\",\"lazy-video\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-message-collapser.hbs",
    "isStrictMode": false
  });
  class ChatMessageCollapser extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get hasUploads() {
      return hasUploads(this.args.uploads);
    }
    get uploadsHeader() {
      let name = "";
      if (this.args.uploads.length === 1) {
        name = this.args.uploads[0].original_filename;
      } else {
        name = _discourseI18n.default.t("chat.uploaded_files", {
          count: this.args.uploads.length
        });
      }
      return (0, _template.htmlSafe)(`<span class="chat-message-collapser-link-small">${(0, _utilities.escapeExpression)(name)}</span>`);
    }
    get cookedBodies() {
      const elements = Array.prototype.slice.call((0, _domFromString.default)(this.args.cooked));
      if (hasLazyVideo(elements)) {
        return this.lazyVideoCooked(elements);
      }
      if (hasImageOnebox(elements)) {
        return this.imageOneboxCooked(elements);
      }
      if (hasImage(elements)) {
        return this.imageCooked(elements);
      }
      if (hasGallery(elements)) {
        return this.galleryCooked(elements);
      }
      return [];
    }
    lazyVideoCooked(elements) {
      return elements.reduce((acc, e) => {
        if (this.siteSettings.lazy_videos_enabled && lazyVideoPredicate(e)) {
          const getVideoAttributes = requirejs("discourse/plugins/discourse-lazy-videos/lib/lazy-video-attributes").default;
          const videoAttributes = getVideoAttributes(e);
          if (this.siteSettings[`lazy_${videoAttributes.providerName}_enabled`]) {
            const link = (0, _utilities.escapeExpression)(videoAttributes.url);
            const title = videoAttributes.title;
            const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link" rel="noopener noreferrer" href="${link}">${title}</a>`);
            acc.push({
              header,
              body: e,
              videoAttributes,
              needsCollapser: true
            });
          } else {
            acc.push({
              body: e,
              needsCollapser: false
            });
          }
        } else {
          acc.push({
            body: e,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
    imageOneboxCooked(elements) {
      return elements.reduce((acc, e) => {
        if (imageOneboxPredicate(e)) {
          let link = animatedImagePredicate(e) ? e.firstChild.src : e.firstElementChild.href;
          link = (0, _utilities.escapeExpression)(link);
          const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link-small" rel="noopener noreferrer" href="${link}">${link}</a>`);
          acc.push({
            header,
            body: e,
            needsCollapser: true
          });
        } else {
          acc.push({
            body: e,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
    imageCooked(elements) {
      return elements.reduce((acc, e) => {
        if (imagePredicate(e)) {
          const link = (0, _utilities.escapeExpression)(e.firstElementChild.src);
          const alt = (0, _utilities.escapeExpression)(e.firstElementChild.alt);
          const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link-small" rel="noopener noreferrer" href="${link}">${alt || link}</a>`);
          acc.push({
            header,
            body: e,
            needsCollapser: true
          });
        } else {
          acc.push({
            body: e,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
    galleryCooked(elements) {
      return elements.reduce((acc, e) => {
        if (galleryPredicate(e)) {
          const link = (0, _utilities.escapeExpression)(e.firstElementChild.href);
          const title = (0, _utilities.escapeExpression)(e.firstElementChild.firstElementChild.textContent);
          e.firstElementChild.removeChild(e.firstElementChild.firstElementChild);
          const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link-small" rel="noopener noreferrer" href="${link}">${title}</a>`);
          acc.push({
            header,
            body: e,
            needsCollapser: true
          });
        } else {
          acc.push({
            body: e,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
  }
  _exports.default = ChatMessageCollapser;
  function lazyVideoPredicate(e) {
    return e.classList.contains("lazy-video-container");
  }
  function hasLazyVideo(elements) {
    return elements.some(e => lazyVideoPredicate(e));
  }
  function animatedImagePredicate(e) {
    return e.firstChild && e.firstChild.nodeName === "IMG" && e.firstChild.classList.contains("animated") && e.firstChild.classList.contains("onebox");
  }
  function externalImageOnebox(e) {
    return e.firstElementChild && e.firstElementChild.nodeName === "A" && e.firstElementChild.classList.contains("onebox") && e.firstElementChild.firstElementChild && e.firstElementChild.firstElementChild.nodeName === "IMG";
  }
  function imageOneboxPredicate(e) {
    return animatedImagePredicate(e) || externalImageOnebox(e);
  }
  function hasImageOnebox(elements) {
    return elements.some(e => imageOneboxPredicate(e));
  }
  function hasUploads(uploads) {
    return uploads?.length > 0;
  }
  function imagePredicate(e) {
    return e.nodeName === "P" && e.firstElementChild && e.firstElementChild.nodeName === "IMG" && !e.firstElementChild.classList.contains("emoji");
  }
  function hasImage(elements) {
    return elements.some(e => imagePredicate(e));
  }
  function galleryPredicate(e) {
    return e.firstElementChild && e.firstElementChild.nodeName === "A" && e.firstElementChild.firstElementChild && e.firstElementChild.firstElementChild.classList.contains("outer-box");
  }
  function hasGallery(elements) {
    return elements.some(e => galleryPredicate(e));
  }
  function isCollapsible(cooked, uploads) {
    const elements = Array.prototype.slice.call((0, _domFromString.default)(cooked));
    return hasLazyVideo(elements) || hasImageOnebox(elements) || hasUploads(uploads) || hasImage(elements) || hasGallery(elements);
  }
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageCollapser);
});