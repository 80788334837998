define("discourse/plugins/chat/discourse/components/chat-composer-uploads", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember-decorators/component", "discourse/lib/uppy-media-optimization-plugin", "discourse/lib/utilities", "discourse/mixins/uppy-upload", "discourse-common/lib/object", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _service, _component2, _uppyMediaOptimizationPlugin, _utilities, _uppyUpload, _object2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showUploadsContainer}}
    <div class="chat-composer-uploads-container">
      {{#each this.uploads as |upload|}}
        <ChatComposerUpload
          @upload={{upload}}
          @isDone={{true}}
          @onCancel={{action "removeUpload" upload}}
        />
      {{/each}}
  
      {{#each this.inProgressUploads as |upload|}}
        <ChatComposerUpload
          @upload={{upload}}
          @onCancel={{action "cancelUploading" upload}}
        />
      {{/each}}
    </div>
  {{/if}}
  
  <PickFilesButton
    @allowMultiple={{true}}
    @fileInputId={{this.fileUploadElementId}}
    @fileInputClass="hidden-upload-field"
  />
  */
  {
    "id": "FoGAB5VV",
    "block": "[[[41,[30,0,[\"showUploadsContainer\"]],[[[1,\"  \"],[10,0],[14,0,\"chat-composer-uploads-container\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"uploads\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@upload\",\"@isDone\",\"@onCancel\"],[[30,1],true,[28,[37,4],[[30,0],\"removeUpload\",[30,1]],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"inProgressUploads\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@upload\",\"@onCancel\"],[[30,2],[28,[37,4],[[30,0],\"cancelUploading\",[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,5],null,[[\"@allowMultiple\",\"@fileInputId\",\"@fileInputClass\"],[true,[30,0,[\"fileUploadElementId\"]],\"hidden-upload-field\"]],null]],[\"upload\",\"upload\"],false,[\"if\",\"each\",\"-track-array\",\"chat-composer-upload\",\"action\",\"pick-files-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-composer-uploads.hbs",
    "isStrictMode": false
  });
  const ChatComposerUploads = dt7948.c(class ChatComposerUploads extends _component.default.extend(_uppyUpload.default) {
    static #_ = (() => dt7948.g(this.prototype, "mediaOptimizationWorker", [_service.service]))();
    #mediaOptimizationWorker = (() => (dt7948.i(this, "mediaOptimizationWorker"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    id = "chat-composer-uploader";
    type = "chat-composer";
    existingUploads = null;
    uploads = null;
    useMultipartUploadsIfAvailable = true;
    uploadDropZone = null;
    init() {
      super.init(...arguments);
      this.setProperties({
        fileInputSelector: `#${this.fileUploadElementId}`
      });
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.inProgressUploads?.length > 0) {
        this._uppyInstance?.cancelAll();
      }
      this.set("uploads", this.existingUploads ? (0, _object2.cloneJSON)(this.existingUploads) : []);
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.composerInputEl?.addEventListener("paste", this._pasteEventListener);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.composerInputEl?.removeEventListener("paste", this._pasteEventListener);
    }
    uploadDone(upload) {
      this.uploads.pushObject(upload);
      this._triggerUploadsChanged();
    }
    showUploadsContainer(uploadsCount, inProgressUploadsCount) {
      return uploadsCount > 0 || inProgressUploadsCount > 0;
    }
    static #_3 = (() => dt7948.n(this.prototype, "showUploadsContainer", [(0, _decorators.default)("uploads.length", "inProgressUploads.length")]))();
    cancelUploading(upload) {
      this.appEvents.trigger(`upload-mixin:${this.id}:cancel-upload`, {
        fileId: upload.id
      });
      this.removeUpload(upload);
    }
    static #_4 = (() => dt7948.n(this.prototype, "cancelUploading", [_object.action]))();
    removeUpload(upload) {
      this.uploads.removeObject(upload);
      this._triggerUploadsChanged();
    }
    static #_5 = (() => dt7948.n(this.prototype, "removeUpload", [_object.action]))();
    _uploadDropTargetOptions() {
      return {
        target: this.uploadDropZone || document.body
      };
    }
    _uppyReady() {
      if (this.siteSettings.composer_media_optimization_image_enabled) {
        this._useUploadPlugin(_uppyMediaOptimizationPlugin.default, {
          optimizeFn: (data, opts) => this.mediaOptimizationWorker.optimizeImage(data, opts),
          runParallel: !this.site.isMobileDevice
        });
      }
      this._onPreProcessProgress(file => {
        const inProgressUpload = this.inProgressUploads.findBy("id", file.id);
        if (!inProgressUpload?.processing) {
          inProgressUpload?.set("processing", true);
        }
      });
      this._onPreProcessComplete(file => {
        const inProgressUpload = this.inProgressUploads.findBy("id", file.id);
        inProgressUpload?.set("processing", false);
      });
    }
    _pasteEventListener(event) {
      if (document.activeElement !== this.composerInputEl) {
        return;
      }
      const {
        canUpload,
        canPasteHtml,
        types
      } = (0, _utilities.clipboardHelpers)(event, {
        siteSettings: this.siteSettings,
        canUpload: true
      });
      if (!canUpload || canPasteHtml || types.includes("text/plain")) {
        return;
      }
      if (event && event.clipboardData && event.clipboardData.files) {
        this._addFiles([...event.clipboardData.files], {
          pasted: true
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "_pasteEventListener", [_decorators.bind]))();
    onProgressUploadsChanged() {
      this._triggerUploadsChanged(this.uploads, {
        inProgressUploadsCount: this.inProgressUploads?.length
      });
    }
    _triggerUploadsChanged() {
      this.onUploadChanged?.(this.uploads, {
        inProgressUploadsCount: this.inProgressUploads?.length
      });
    }
  }, [(0, _component2.classNames)("chat-composer-uploads")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatComposerUploads);
});