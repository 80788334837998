define("discourse/plugins/chat/discourse/routes/chat-channel-threads", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelThreads extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatThreadListPane", [_service.service]))();
    #chatThreadListPane = (() => (dt7948.i(this, "chatThreadListPane"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    beforeModel(transition) {
      const channel = this.modelFor("chat.channel");
      if (!channel.threadingEnabled) {
        transition.abort();
        this.router.transitionTo("chat.channel", ...channel.routeModels);
        return;
      }
      this.chatStateManager.openSidePanel();
    }
    activate() {
      this.chat.activeMessage = null;
    }
    static #_5 = (() => dt7948.n(this.prototype, "activate", [_object.action]))();
    willTransition(transition) {
      if (transition.targetName === "chat.channel.index" || transition.targetName === "chat.channel.near-message" || transition.targetName === "chat.index" || !transition.targetName.startsWith("chat")) {
        this.chatStateManager.closeSidePanel();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "willTransition", [_object.action]))();
  }
  _exports.default = ChatChannelThreads;
});