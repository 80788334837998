define("discourse/plugins/chat/discourse/services/chat-drawer-size", ["exports", "@ember/service", "discourse/lib/key-value-store"], function (_exports, _service, _keyValueStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerSize extends _service.default {
    STORE_NAMESPACE = "discourse_chat_drawer_size_";
    MIN_HEIGHT = 300;
    MIN_WIDTH = 250;
    store = (() => new _keyValueStore.default(this.STORE_NAMESPACE))();
    get size() {
      return {
        width: this.store.getObject("width") || 400,
        height: this.store.getObject("height") || 530
      };
    }
    set size(_ref) {
      let {
        width,
        height
      } = _ref;
      this.store.setObject({
        key: "width",
        value: this.#min(width, this.MIN_WIDTH)
      });
      this.store.setObject({
        key: "height",
        value: this.#min(height, this.MIN_HEIGHT)
      });
    }
    #min(number, min) {
      return Math.max(number, min);
    }
  }
  _exports.default = ChatDrawerSize;
});