define("discourse/plugins/chat/discourse/helpers/first-visible-message-id", ["exports", "discourse/plugins/chat/discourse/lib/check-message-visibility"], function (_exports, _checkMessageVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = firstVisibleMessageId;
  function firstVisibleMessageId(container) {
    let _found;
    const messages = container.querySelectorAll(":scope .chat-messages-container > [data-id]");
    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i];
      if ((0, _checkMessageVisibility.checkMessageBottomVisibility)(container, message)) {
        _found = message;
        break;
      }
    }
    const id = _found?.dataset?.id;
    return id ? parseInt(id, 10) : null;
  }
});