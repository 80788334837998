define("discourse/plugins/chat/discourse/routes/chat-new-message", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNewMessageRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel(transition) {
      const recipients = this.paramsFor(this.routeName).recipients?.split(",");
      if (!recipients) {
        transition.abort();
        return this.router.transitionTo("chat");
      }
      this.chat.upsertDmChannel({
        usernames: recipients
      }).then(channel => {
        this.router.transitionTo("chat.channel", channel.title, channel.id);
      });
    }
  }
  _exports.default = ChatNewMessageRoute;
});