define("discourse/plugins/chat/discourse/models/chat-direct-message", ["exports", "@glimmer/tracking", "discourse/models/user", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _tracking, _user, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDirectMessage {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatDirectMessage(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "users", [_tracking.tracked]))();
    #users = (() => (dt7948.i(this, "users"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "group", [_tracking.tracked]))();
    #group = (() => (dt7948.i(this, "group"), void 0))();
    type = (() => _chatChannel.CHATABLE_TYPES.directMessageChannel)();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.group = args.group ?? false;
      this.users = this.#initUsers(args.users || []);
    }
    #initUsers(users) {
      return users.map(user => {
        if (!user || user instanceof _user.default) {
          return user;
        } else {
          return _user.default.create(user);
        }
      });
    }
  }
  _exports.default = ChatDirectMessage;
});