define("discourse/plugins/chat/discourse/models/chat-message", ["exports", "@glimmer/tracking", "@ember-compat/tracked-built-ins", "discourse/lib/text", "discourse/models/bookmark", "discourse/models/user", "discourse-common/lib/get-owner", "discourse-common/lib/later", "discourse/plugins/chat/discourse/lib/transform-auto-links", "discourse/plugins/chat/discourse/models/chat-message-reaction"], function (_exports, _tracking, _trackedBuiltIns, _text, _bookmark3, _user3, _getOwner, _later, _transformAutoLinks, _chatMessageReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessage {
    static cookFunction = null;
    static create(channel) {
      let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new ChatMessage(channel, args);
    }
    static createDraftMessage(channel) {
      let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      args.draft = true;
      return ChatMessage.create(channel, args);
    }
    static #_ = (() => dt7948.g(this.prototype, "id", [_tracking.tracked]))();
    #id = (() => (dt7948.i(this, "id"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked]))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selected", [_tracking.tracked]))();
    #selected = (() => (dt7948.i(this, "selected"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "channel", [_tracking.tracked]))();
    #channel = (() => (dt7948.i(this, "channel"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "staged", [_tracking.tracked]))();
    #staged = (() => (dt7948.i(this, "staged"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "processed", [_tracking.tracked]))();
    #processed = (() => (dt7948.i(this, "processed"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "draftSaved", [_tracking.tracked]))();
    #draftSaved = (() => (dt7948.i(this, "draftSaved"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "draft", [_tracking.tracked]))();
    #draft = (() => (dt7948.i(this, "draft"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "createdAt", [_tracking.tracked]))();
    #createdAt = (() => (dt7948.i(this, "createdAt"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "uploads", [_tracking.tracked]))();
    #uploads = (() => (dt7948.i(this, "uploads"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "excerpt", [_tracking.tracked]))();
    #excerpt = (() => (dt7948.i(this, "excerpt"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "reactions", [_tracking.tracked]))();
    #reactions = (() => (dt7948.i(this, "reactions"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "reviewableId", [_tracking.tracked]))();
    #reviewableId = (() => (dt7948.i(this, "reviewableId"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "user", [_tracking.tracked]))();
    #user = (() => (dt7948.i(this, "user"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "inReplyTo", [_tracking.tracked]))();
    #inReplyTo = (() => (dt7948.i(this, "inReplyTo"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked]))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    static #_17 = (() => dt7948.g(this.prototype, "bookmark", [_tracking.tracked]))();
    #bookmark = (() => (dt7948.i(this, "bookmark"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "userFlagStatus", [_tracking.tracked]))();
    #userFlagStatus = (() => (dt7948.i(this, "userFlagStatus"), void 0))();
    static #_19 = (() => dt7948.g(this.prototype, "hidden", [_tracking.tracked]))();
    #hidden = (() => (dt7948.i(this, "hidden"), void 0))();
    static #_20 = (() => dt7948.g(this.prototype, "version", [_tracking.tracked], function () {
      return 0;
    }))();
    #version = (() => (dt7948.i(this, "version"), void 0))();
    static #_21 = (() => dt7948.g(this.prototype, "edited", [_tracking.tracked]))();
    #edited = (() => (dt7948.i(this, "edited"), void 0))();
    static #_22 = (() => dt7948.g(this.prototype, "editing", [_tracking.tracked]))();
    #editing = (() => (dt7948.i(this, "editing"), void 0))();
    static #_23 = (() => dt7948.g(this.prototype, "chatWebhookEvent", [_tracking.tracked]))();
    #chatWebhookEvent = (() => (dt7948.i(this, "chatWebhookEvent"), void 0))();
    static #_24 = (() => dt7948.g(this.prototype, "mentionWarning", [_tracking.tracked]))();
    #mentionWarning = (() => (dt7948.i(this, "mentionWarning"), void 0))();
    static #_25 = (() => dt7948.g(this.prototype, "availableFlags", [_tracking.tracked]))();
    #availableFlags = (() => (dt7948.i(this, "availableFlags"), void 0))();
    static #_26 = (() => dt7948.g(this.prototype, "newest", [_tracking.tracked]))();
    #newest = (() => (dt7948.i(this, "newest"), void 0))();
    static #_27 = (() => dt7948.g(this.prototype, "highlighted", [_tracking.tracked]))();
    #highlighted = (() => (dt7948.i(this, "highlighted"), void 0))();
    static #_28 = (() => dt7948.g(this.prototype, "firstOfResults", [_tracking.tracked]))();
    #firstOfResults = (() => (dt7948.i(this, "firstOfResults"), void 0))();
    static #_29 = (() => dt7948.g(this.prototype, "message", [_tracking.tracked]))();
    #message = (() => (dt7948.i(this, "message"), void 0))();
    static #_30 = (() => dt7948.g(this.prototype, "manager", [_tracking.tracked]))();
    #manager = (() => (dt7948.i(this, "manager"), void 0))();
    static #_31 = (() => dt7948.g(this.prototype, "deletedById", [_tracking.tracked]))();
    #deletedById = (() => (dt7948.i(this, "deletedById"), void 0))();
    static #_32 = (() => dt7948.g(this.prototype, "streaming", [_tracking.tracked]))();
    #streaming = (() => (dt7948.i(this, "streaming"), void 0))();
    static #_33 = (() => dt7948.g(this.prototype, "_deletedAt", [_tracking.tracked]))();
    #_deletedAt = (() => (dt7948.i(this, "_deletedAt"), void 0))();
    static #_34 = (() => dt7948.g(this.prototype, "_cooked", [_tracking.tracked]))();
    #_cooked = (() => (dt7948.i(this, "_cooked"), void 0))();
    static #_35 = (() => dt7948.g(this.prototype, "_thread", [_tracking.tracked]))();
    #_thread = (() => (dt7948.i(this, "_thread"), void 0))();
    constructor(channel) {
      let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.id = args.id;
      this.channel = channel;
      this.streaming = args.streaming;
      this.manager = args.manager;
      this.newest = args.newest ?? false;
      this.draftSaved = args.draftSaved ?? args.draft_saved ?? false;
      this.firstOfResults = args.firstOfResults ?? args.first_of_results ?? false;
      this.staged = args.staged ?? false;
      this.processed = args.processed ?? true;
      this.edited = args.edited ?? false;
      this.editing = args.editing ?? false;
      this.availableFlags = args.availableFlags ?? args.available_flags;
      this.hidden = args.hidden ?? false;
      this.chatWebhookEvent = args.chatWebhookEvent ?? args.chat_webhook_event;
      this.createdAt = args.created_at ? new Date(args.created_at) : new Date(args.createdAt);
      this.deletedById = args.deletedById || args.deleted_by_id;
      this._deletedAt = args.deletedAt || args.deleted_at;
      this.expanded = this.hidden || this._deletedAt ? false : args.expanded ?? true;
      this.excerpt = args.excerpt;
      this.reviewableId = args.reviewableId ?? args.reviewable_id;
      this.userFlagStatus = args.userFlagStatus ?? args.user_flag_status;
      this.draft = args.draft;
      this.message = args.message ?? "";
      this._cooked = args.cooked ?? "";
      this.inReplyTo = args.inReplyTo ?? (args.in_reply_to ?? args.replyToMsg ? ChatMessage.create(channel, args.in_reply_to ?? args.replyToMsg) : null);
      this.reactions = this.#initChatMessageReactionModel(args.reactions);
      this.uploads = new _trackedBuiltIns.TrackedArray(args.uploads || []);
      this.user = this.#initUserModel(args.user);
      this.bookmark = args.bookmark ? _bookmark3.default.create(args.bookmark) : null;
      this.mentionedUsers = this.#initMentionedUsers(args.mentioned_users);
      if (args.thread) {
        this.thread = args.thread;
      }
    }
    get persisted() {
      return !!this.id && !this.staged;
    }
    get replyable() {
      return !this.staged && !this.error;
    }
    get editable() {
      return !this.staged && !this.error;
    }
    get thread() {
      return this._thread;
    }
    set thread(thread) {
      if (!thread) {
        this._thread = null;
        return;
      }
      this._thread = this.channel.threadsManager.add(this.channel, thread, {
        replace: true
      });
    }
    get deletedAt() {
      return this._deletedAt;
    }
    set deletedAt(value) {
      this._deletedAt = value;
      this.incrementVersion();
    }
    get cooked() {
      return this._cooked;
    }
    set cooked(newCooked) {
      // some markdown is cooked differently on the server-side, e.g.
      // quotes, avatar images etc.
      if (newCooked !== this._cooked) {
        this._cooked = newCooked;
        this.incrementVersion();
      }
    }
    async cook() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      await this.#ensureCookFunctionInitialized();
      this.cooked = ChatMessage.cookFunction(this.message);
    }
    get read() {
      return this.channel.currentUserMembership?.lastReadMessageId >= this.id;
    }
    get isOriginalThreadMessage() {
      return this.thread?.originalMessage?.id === this.id;
    }
    get index() {
      return this.manager?.messages?.indexOf(this);
    }
    static #_36 = (() => dt7948.n(this.prototype, "index", [_tracking.cached]))();
    get previousMessage() {
      return this.manager?.messages?.objectAt?.(this.index - 1);
    }
    static #_37 = (() => dt7948.n(this.prototype, "previousMessage", [_tracking.cached]))();
    get nextMessage() {
      return this.manager?.messages?.objectAt?.(this.index + 1);
    }
    static #_38 = (() => dt7948.n(this.prototype, "nextMessage", [_tracking.cached]))();
    highlight() {
      this.highlighted = true;
      (0, _later.default)(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.highlighted = false;
      }, 2000);
    }
    incrementVersion() {
      this.version++;
    }
    async parseMentions() {
      return await (0, _text.parseMentions)(this.message, this.#markdownOptions);
    }
    toJSONDraft() {
      if (this.message?.length === 0 && this.uploads?.length === 0 && !this.inReplyTo) {
        return null;
      }
      const data = {};
      if (this.uploads?.length > 0) {
        data.uploads = this.uploads;
      }
      if (this.message?.length > 0) {
        data.message = this.message;
      }
      if (this.inReplyTo) {
        data.replyToMsg = {
          id: this.inReplyTo.id,
          excerpt: this.inReplyTo.excerpt,
          user: {
            id: this.inReplyTo.user.id,
            name: this.inReplyTo.user.name,
            avatar_template: this.inReplyTo.user.avatar_template,
            username: this.inReplyTo.user.username
          }
        };
      }
      if (this.editing) {
        data.editing = true;
        data.id = this.id;
        data.excerpt = this.excerpt;
      }
      return JSON.stringify(data);
    }
    react(emoji, action, actor, currentUserId) {
      const selfReaction = actor.id === currentUserId;
      const existingReaction = this.reactions.find(reaction => reaction.emoji === emoji);
      if (existingReaction) {
        if (action === "add") {
          if (selfReaction && existingReaction.reacted) {
            return;
          }

          // we might receive a message bus event while loading a channel who would
          // already have the reaction added to the message
          if (existingReaction.users.find(user => user.id === actor.id)) {
            return;
          }
          existingReaction.count = existingReaction.count + 1;
          if (selfReaction) {
            existingReaction.reacted = true;
          }
          existingReaction.users.pushObject(actor);
        } else {
          const existingUserReaction = existingReaction.users.find(user => user.id === actor.id);
          if (!existingUserReaction) {
            return;
          }
          if (selfReaction) {
            existingReaction.reacted = false;
          }
          if (existingReaction.count === 1) {
            this.reactions.removeObject(existingReaction);
          } else {
            existingReaction.count = existingReaction.count - 1;
            existingReaction.users.removeObject(existingUserReaction);
          }
        }
      } else {
        if (action === "add") {
          this.reactions.pushObject(_chatMessageReaction.default.create({
            count: 1,
            emoji,
            reacted: selfReaction,
            users: [actor]
          }));
        }
      }
    }
    async #ensureCookFunctionInitialized() {
      if (ChatMessage.cookFunction) {
        return;
      }
      const cookFunction = await (0, _text.generateCookFunction)(this.#markdownOptions);
      ChatMessage.cookFunction = raw => {
        return (0, _transformAutoLinks.default)(cookFunction(raw));
      };
    }
    get #markdownOptions() {
      const site = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site");
      return {
        featuresOverride: site.markdown_additional_options?.chat?.limited_pretty_text_features,
        markdownItRules: site.markdown_additional_options?.chat?.limited_pretty_text_markdown_rules,
        hashtagTypesInPriorityOrder: site.hashtag_configurations?.["chat-composer"],
        hashtagIcons: site.hashtag_icons
      };
    }
    #initChatMessageReactionModel() {
      let reactions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return reactions.map(reaction => _chatMessageReaction.default.create(reaction));
    }
    #initMentionedUsers(mentionedUsers) {
      const map = new Map();
      if (mentionedUsers) {
        mentionedUsers.forEach(userData => {
          const user = _user3.default.create(userData);
          map.set(user.id, user);
        });
      }
      return map;
    }
    #initUserModel(user) {
      if (!user || user instanceof _user3.default) {
        return user;
      }
      return _user3.default.create(user);
    }
  }
  _exports.default = ChatMessage;
});