define("discourse/plugins/chat/discourse/models/chat-chatable", ["exports", "@glimmer/tracking", "@ember/service", "discourse/models/category", "discourse/models/group", "discourse/models/user", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _tracking3, _service, _category, _group, _user, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChatable {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatChatable(args);
    }
    static createUser(model) {
      return new ChatChatable({
        type: "user",
        model,
        identifier: `u-${model.id}`
      });
    }
    static createGroup(model) {
      return new ChatChatable({
        type: "group",
        model,
        identifier: `g-${model.id}`
      });
    }
    static createChannel(model) {
      return new ChatChatable({
        type: "channel",
        model,
        identifier: `c-${model.id}`
      });
    }
    static #_ = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "identifier", [_tracking3.tracked]))();
    #identifier = (() => (dt7948.i(this, "identifier"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "type", [_tracking3.tracked]))();
    #type = (() => (dt7948.i(this, "type"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "model", [_tracking3.tracked]))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "enabled", [_tracking3.tracked], function () {
      return true;
    }))();
    #enabled = (() => (dt7948.i(this, "enabled"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "tracking", [_tracking3.tracked]))();
    #tracking = (() => (dt7948.i(this, "tracking"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.identifier = args.identifier;
      this.type = args.type;
      switch (this.type) {
        case "channel":
          if (args.model.chatable?.users?.length === 1) {
            this.enabled = args.model.chatable?.users[0].has_chat_enabled;
          }
          if (args.model instanceof _chatChannel.default) {
            this.model = args.model;
            break;
          }
          this.model = _chatChannel.default.create(args.model);
          break;
        case "user":
          this.enabled = args.model.has_chat_enabled;
          if (args.model instanceof _user.default) {
            this.model = args.model;
            break;
          }
          this.model = _user.default.create(args.model);
          break;
        case "group":
          this.enabled = args.model.can_chat;
          if (args.model instanceof _group.default) {
            this.model = args.model;
            break;
          }
          this.model = _group.default.create(args.model);
          break;
      }
    }
    get isUser() {
      return this.type === "user";
    }
    get isGroup() {
      return this.type === "group";
    }
    get isCategory() {
      return this instanceof _category.default;
    }
  }
  _exports.default = ChatChatable;
});