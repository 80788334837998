define("discourse/plugins/chat/discourse/components/chat-footer", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/chat/footer/unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _dButton, _concatClass, _i18n, _unreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatFooter extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatHistory", [_service.service]))();
    #chatHistory = (() => (dt7948.i(this, "chatHistory"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    get includeThreads() {
      if (!this.siteSettings.chat_threads_enabled) {
        return false;
      }
      return this.chatChannelsManager.hasThreadedChannels;
    }
    get directMessagesEnabled() {
      return this.chat.userCanAccessDirectMessages;
    }
    get currentRouteName() {
      const routeName1 = this.chatHistory.currentRoute?.name;
      return routeName1 === "chat" ? "chat.channels" : routeName1;
    }
    get enabledRouteCount() {
      return [this.includeThreads, this.directMessagesEnabled, this.siteSettings.enable_public_channels].filter(Boolean).length;
    }
    get shouldRenderFooter() {
      return (this.site.mobileView || this.chatStateManager.isDrawerExpanded) && this.chatStateManager.hasPreloadedChannels && this.enabledRouteCount > 1;
    }
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRenderFooter}}
          <nav class="c-footer">
            <DButton
              @route="chat.channels"
              @icon="comments"
              @translatedLabel={{i18n "chat.channel_list.title"}}
              aria-label={{i18n "chat.channel_list.aria_label"}}
              id="c-footer-channels"
              class={{concatClass
                "btn-transparent"
                "c-footer__item"
                (if (eq this.currentRouteName "chat.channels") "--active")
              }}
            >
              <UnreadChannelsIndicator />
            </DButton>
    
            {{#if this.directMessagesEnabled}}
              <DButton
                @route="chat.direct-messages"
                @icon="users"
                @translatedLabel={{i18n "chat.direct_messages.title"}}
                aria-label={{i18n "chat.direct_messages.aria_label"}}
                id="c-footer-direct-messages"
                class={{concatClass
                  "btn-transparent"
                  "c-footer__item"
                  (if (eq this.currentRouteName "chat.direct-messages") "--active")
                }}
              >
                <UnreadDirectMessagesIndicator />
              </DButton>
            {{/if}}
    
            {{#if this.includeThreads}}
              <DButton
                @route="chat.threads"
                @icon="discourse-threads"
                @translatedLabel={{i18n "chat.my_threads.title"}}
                aria-label={{i18n "chat.my_threads.aria_label"}}
                id="c-footer-threads"
                class={{concatClass
                  "btn-transparent"
                  "c-footer__item"
                  (if (eq this.currentRouteName "chat.threads") "--active")
                }}
              >
                <UnreadThreadsIndicator />
              </DButton>
            {{/if}}
          </nav>
        {{/if}}
      
    */
    {
      "id": "3LZPbP4o",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRenderFooter\"]],[[[1,\"      \"],[10,\"nav\"],[14,0,\"c-footer\"],[12],[1,\"\\n        \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.channel_list.aria_label\"],null]],[24,1,\"c-footer-channels\"],[16,0,[28,[32,2],[\"btn-transparent\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"currentRouteName\"]],\"chat.channels\"],null],\"--active\"]],null]]],[[\"@route\",\"@icon\",\"@translatedLabel\"],[\"chat.channels\",\"comments\",[28,[32,1],[\"chat.channel_list.title\"],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,4],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"directMessagesEnabled\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.direct_messages.aria_label\"],null]],[24,1,\"c-footer-direct-messages\"],[16,0,[28,[32,2],[\"btn-transparent\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"currentRouteName\"]],\"chat.direct-messages\"],null],\"--active\"]],null]]],[[\"@route\",\"@icon\",\"@translatedLabel\"],[\"chat.direct-messages\",\"users\",[28,[32,1],[\"chat.direct_messages.title\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,5],null,null,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"includeThreads\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.my_threads.aria_label\"],null]],[24,1,\"c-footer-threads\"],[16,0,[28,[32,2],[\"btn-transparent\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"currentRouteName\"]],\"chat.threads\"],null],\"--active\"]],null]]],[[\"@route\",\"@icon\",\"@translatedLabel\"],[\"chat.threads\",\"discourse-threads\",[28,[32,1],[\"chat.my_threads.title\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,6],null,null,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-footer.js",
      "scope": () => [_dButton.default, _i18n.default, _concatClass.default, _truthHelpers.eq, _unreadIndicator.UnreadChannelsIndicator, _unreadIndicator.UnreadDirectMessagesIndicator, _unreadIndicator.UnreadThreadsIndicator],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatFooter;
});