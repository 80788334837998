define("discourse/plugins/chat/discourse/models/chat-thread", ["exports", "@glimmer/tracking", "pretty-text/guid", "discourse-common/lib/get-owner", "discourse/plugins/chat/discourse/lib/chat-messages-manager", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-thread-preview", "discourse/plugins/chat/discourse/models/chat-tracking-state", "discourse/plugins/chat/discourse/models/user-chat-thread-membership"], function (_exports, _tracking3, _guid, _getOwner, _chatMessagesManager, _chatMessage, _chatThreadPreview, _chatTrackingState, _userChatThreadMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.THREAD_STATUSES = void 0;
  const THREAD_STATUSES = _exports.THREAD_STATUSES = {
    open: "open",
    readOnly: "read_only",
    closed: "closed",
    archived: "archived"
  };
  class ChatThread {
    static create(channel) {
      let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new ChatThread(channel, args);
    }
    static #_ = (() => dt7948.g(this.prototype, "id", [_tracking3.tracked]))();
    #id = (() => (dt7948.i(this, "id"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "title", [_tracking3.tracked]))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "status", [_tracking3.tracked]))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "draft", [_tracking3.tracked]))();
    #draft = (() => (dt7948.i(this, "draft"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "staged", [_tracking3.tracked]))();
    #staged = (() => (dt7948.i(this, "staged"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "channel", [_tracking3.tracked]))();
    #channel = (() => (dt7948.i(this, "channel"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "originalMessage", [_tracking3.tracked]))();
    #originalMessage = (() => (dt7948.i(this, "originalMessage"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "lastMessageId", [_tracking3.tracked]))();
    #lastMessageId = (() => (dt7948.i(this, "lastMessageId"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "threadMessageBusLastId", [_tracking3.tracked]))();
    #threadMessageBusLastId = (() => (dt7948.i(this, "threadMessageBusLastId"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "replyCount", [_tracking3.tracked]))();
    #replyCount = (() => (dt7948.i(this, "replyCount"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "tracking", [_tracking3.tracked]))();
    #tracking = (() => (dt7948.i(this, "tracking"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "currentUserMembership", [_tracking3.tracked]))();
    #currentUserMembership = (() => (dt7948.i(this, "currentUserMembership"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "preview", [_tracking3.tracked]))();
    #preview = (() => (dt7948.i(this, "preview"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "force", [_tracking3.tracked]))();
    #force = (() => (dt7948.i(this, "force"), void 0))();
    messagesManager = (() => new _chatMessagesManager.default((0, _getOwner.getOwnerWithFallback)(this)))();
    constructor(channel) {
      let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.id = args.id;
      this.channel = channel;
      this.status = args.status;
      this.staged = args.staged;
      this.replyCount = args.reply_count;
      this.force = args.force;
      this.originalMessage = args.original_message ? _chatMessage.default.create(channel, args.original_message) : null;
      if (this.originalMessage) {
        this.originalMessage.thread = this;
      }
      this.lastMessageId = args.last_message_id;
      this.title = args.title;
      if (args.current_user_membership) {
        this.currentUserMembership = _userChatThreadMembership.default.create(args.current_user_membership);
      }
      this.tracking = new _chatTrackingState.default((0, _getOwner.getOwnerWithFallback)(this));
      this.preview = _chatThreadPreview.default.create(args.preview);
    }
    resetDraft(user) {
      this.draft = _chatMessage.default.createDraftMessage(this.channel, {
        user,
        thread: this
      });
    }
    async stageMessage(message) {
      message.id = (0, _guid.default)();
      message.staged = true;
      message.processed = false;
      message.draft = false;
      message.createdAt = new Date();
      message.thread = this;
      this.messagesManager.addMessages([message]);
      message.manager = this.messagesManager;
    }
    get routeModels() {
      return [...this.channel.routeModels, this.id];
    }
  }
  _exports.default = ChatThread;
});