define("discourse/plugins/chat/discourse/components/chat-retention-reminder-text", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRetentionReminderText extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get type() {
      return this.args.type ?? "long";
    }
    get text() {
      if (this.args.channel.isDirectMessageChannel) {
        if (this.#countForChannelType > 0) {
          return _discourseI18n.default.t(`chat.retention_reminders.${this.type}`, {
            count: this.siteSettings.chat_dm_retention_days
          });
        } else {
          return _discourseI18n.default.t(`chat.retention_reminders.indefinitely_${this.type}`);
        }
      } else {
        if (this.#countForChannelType > 0) {
          return _discourseI18n.default.t(`chat.retention_reminders.${this.type}`, {
            count: this.siteSettings.chat_channel_retention_days
          });
        } else {
          return _discourseI18n.default.t(`chat.retention_reminders.indefinitely_${this.type}`);
        }
      }
    }
    get #countForChannelType() {
      return this.args.channel.isDirectMessageChannel ? this.siteSettings.chat_dm_retention_days : this.siteSettings.chat_channel_retention_days;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="chat-retention-reminder-text">
          {{this.text}}
        </span>
      
    */
    {
      "id": "jf5EPrBy",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"chat-retention-reminder-text\"],[12],[1,\"\\n      \"],[1,[30,0,[\"text\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-retention-reminder-text.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRetentionReminderText;
});