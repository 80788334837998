define("discourse/plugins/chat/discourse/templates/chat-browse-all", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BrowseChannels @currentTab="all" />
  */
  {
    "id": "gKPEawyy",
    "block": "[[[8,[39,0],null,[[\"@currentTab\"],[\"all\"]],null]],[],false,[\"browse-channels\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-browse-all.hbs",
    "isStrictMode": false
  });
});