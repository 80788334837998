define("discourse/plugins/chat/discourse/routes/chat-channel-near-message-with-thread", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This route is only here as a convenience method for a clean `/c/:channelTitle/:channelId/:messageId/t/:threadId` URL.
  // It's not a real route, it just redirects to the real route after setting a param on the controller.
  class ChatChannelNearMessageWithThread extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    beforeModel() {
      const channel = this.modelFor("chat-channel");
      const {
        messageId,
        threadId
      } = this.paramsFor(this.routeName);
      this.controllerFor("chat-channel").set("messageId", null);
      if (messageId || this.controllerFor("chat-channel").get("targetMessageId")) {
        this.controllerFor("chat-channel").set("targetMessageId", messageId);
      }
      if (threadId && this.site.desktopView) {
        this.router.replaceWith("chat.channel.thread", ...channel.routeModels, threadId);
      }
    }
  }
  _exports.default = ChatChannelNearMessageWithThread;
});