define("discourse/plugins/chat/discourse/routes/preferences-chat", ["exports", "@ember/service", "discourse/lib/utilities", "discourse/routes/restricted-user"], function (_exports, _service, _utilities, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesChatRoute extends _restrictedUser.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    showFooter = true;
    setupController(controller, user) {
      if (!this.siteSettings.chat_enabled || !user.can_chat && !this.currentUser?.admin) {
        return this.router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
      }
      controller.set("model", user);
    }
  }
  _exports.default = PreferencesChatRoute;
});