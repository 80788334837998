define("discourse/plugins/chat/discourse/services/chat-state-manager", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/key-value-store", "discourse/lib/plugin-api", "discourse/lib/sidebar/panels", "discourse/lib/utilities", "discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode"], function (_exports, _tracking, _service, _keyValueStore, _pluginApi, _panels, _utilities, _getUserChatSeparateSidebarMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addChatDrawerStateCallback = addChatDrawerStateCallback;
  _exports.default = void 0;
  _exports.resetChatDrawerStateCallbacks = resetChatDrawerStateCallbacks;
  const PREFERRED_MODE_KEY = "preferred_mode";
  const PREFERRED_MODE_STORE_NAMESPACE = "discourse_chat_";
  const FULL_PAGE_CHAT = "FULL_PAGE_CHAT";
  const DRAWER_CHAT = "DRAWER_CHAT";
  let chatDrawerStateCallbacks = [];
  function addChatDrawerStateCallback(callback) {
    chatDrawerStateCallbacks.push(callback);
  }
  function resetChatDrawerStateCallbacks() {
    chatDrawerStateCallbacks = [];
  }
  class ChatStateManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatHistory", [_service.service]))();
    #chatHistory = (() => (dt7948.i(this, "chatHistory"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "chatDrawerRouter", [_service.service]))();
    #chatDrawerRouter = (() => (dt7948.i(this, "chatDrawerRouter"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "isSidePanelExpanded", [_tracking.tracked], function () {
      return false;
    }))();
    #isSidePanelExpanded = (() => (dt7948.i(this, "isSidePanelExpanded"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "isDrawerExpanded", [_tracking.tracked], function () {
      return false;
    }))();
    #isDrawerExpanded = (() => (dt7948.i(this, "isDrawerExpanded"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "isDrawerActive", [_tracking.tracked], function () {
      return false;
    }))();
    #isDrawerActive = (() => (dt7948.i(this, "isDrawerActive"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "hasPreloadedChannels", [_tracking.tracked], function () {
      return false;
    }))();
    #hasPreloadedChannels = (() => (dt7948.i(this, "hasPreloadedChannels"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "_chatURL", [_tracking.tracked], function () {
      return null;
    }))();
    #_chatURL = (() => (dt7948.i(this, "_chatURL"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "_appURL", [_tracking.tracked], function () {
      return null;
    }))();
    #_appURL = (() => (dt7948.i(this, "_appURL"), void 0))();
    _store = (() => new _keyValueStore.default(PREFERRED_MODE_STORE_NAMESPACE))();
    reset() {
      this._store.remove(PREFERRED_MODE_KEY);
      this._chatURL = null;
      this._appURL = null;
    }
    prefersFullPage() {
      this._store.setObject({
        key: PREFERRED_MODE_KEY,
        value: FULL_PAGE_CHAT
      });
    }
    prefersDrawer() {
      this._store.setObject({
        key: PREFERRED_MODE_KEY,
        value: DRAWER_CHAT
      });
    }
    openSidePanel() {
      this.isSidePanelExpanded = true;
    }
    closeSidePanel() {
      this.isSidePanelExpanded = false;
    }
    didOpenDrawer() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        const adminSidebarStateManager = api.container.lookup("service:admin-sidebar-state-manager");
        if (adminSidebarStateManager === undefined || !adminSidebarStateManager.maybeForceAdminSidebar()) {
          if ((0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(this.currentUser).always) {
            api.setSeparatedSidebarMode();
            api.hideSidebarSwitchPanelButtons();
          } else {
            api.setCombinedSidebarMode();
          }
        }
      });
      this.isDrawerActive = true;
      this.isDrawerExpanded = true;
      if (url) {
        this.storeChatURL(url);
      }
      this.chat.updatePresence();
      this.#publishStateChange();
    }
    didCloseDrawer() {
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        const adminSidebarStateManager = api.container.lookup("service:admin-sidebar-state-manager");
        const chatSeparateSidebarMode = (0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(this.currentUser);
        if (adminSidebarStateManager === undefined || !adminSidebarStateManager.maybeForceAdminSidebar()) {
          api.setSidebarPanel(_panels.MAIN_PANEL);
          if (chatSeparateSidebarMode.fullscreen) {
            api.setCombinedSidebarMode();
            api.showSidebarSwitchPanelButtons();
          } else if (chatSeparateSidebarMode.always) {
            api.setSeparatedSidebarMode();
            api.showSidebarSwitchPanelButtons();
          } else {
            api.setCombinedSidebarMode();
            api.hideSidebarSwitchPanelButtons();
          }
        }
      });
      this.chatDrawerRouter.currentRouteName = null;
      this.isDrawerActive = false;
      this.isDrawerExpanded = false;
      this.chat.updatePresence();
      this.#publishStateChange();
    }
    didExpandDrawer() {
      this.isDrawerActive = true;
      this.isDrawerExpanded = true;
      this.chat.updatePresence();
    }
    didCollapseDrawer() {
      this.isDrawerActive = true;
      this.isDrawerExpanded = false;
      this.#publishStateChange();
    }
    didToggleDrawer() {
      this.isDrawerExpanded = !this.isDrawerExpanded;
      this.isDrawerActive = true;
      this.#publishStateChange();
    }
    get isFullPagePreferred() {
      return !!(this.site.mobileView || this._store.getObject(PREFERRED_MODE_KEY) === FULL_PAGE_CHAT);
    }
    get isDrawerPreferred() {
      return !!(!this.isFullPagePreferred || this.site.desktopView && (!this._store.getObject(PREFERRED_MODE_KEY) || this._store.getObject(PREFERRED_MODE_KEY) === DRAWER_CHAT));
    }
    get isFullPageActive() {
      return this.router.currentRouteName?.startsWith("chat");
    }
    get isActive() {
      return this.isFullPageActive || this.isDrawerActive;
    }
    storeAppURL() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (url) {
        this._appURL = url;
      } else if (this.router.currentURL?.startsWith("/chat")) {
        this._appURL = "/";
      } else {
        this._appURL = this.router.currentURL;
      }
    }
    storeChatURL(url) {
      this._chatURL = url;
    }
    get lastKnownAppURL() {
      const url = this._appURL;
      if (url && url !== "/") {
        return url;
      }
      return this.router.urlFor(`discovery.${(0, _utilities.defaultHomepage)()}`);
    }
    get lastKnownChatURL() {
      return this._chatURL || "/chat";
    }
    #publishStateChange() {
      const state = {
        isDrawerActive: this.isDrawerActive,
        isDrawerExpanded: this.isDrawerExpanded
      };
      chatDrawerStateCallbacks.forEach(callback => callback(state));
    }
  }
  _exports.default = ChatStateManager;
});