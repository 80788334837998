define("discourse/plugins/chat/discourse/services/chat-admin-api", ["exports", "@ember/service", "discourse/lib/ajax"], function (_exports, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatAdminApi extends _service.default {
    async exportMessages() {
      await this.#post(`/export/messages`);
    }
    get #basePath() {
      return "/chat/admin";
    }
    #post(endpoint) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _ajax.ajax)(`${this.#basePath}${endpoint}`, {
        type: "POST",
        data
      });
    }
  }
  _exports.default = ChatAdminApi;
});