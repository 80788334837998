define("discourse/plugins/chat/discourse/templates/connectors/user-profile-controls/chat-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.can_chat_user}}
    <Chat::DirectMessageButton @user={{this.model}} />
  {{/if}}
  */
  {
    "id": "cuKvLOv5",
    "block": "[[[41,[30,0,[\"model\",\"can_chat_user\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@user\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"chat/direct-message-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/connectors/user-profile-controls/chat-button.hbs",
    "isStrictMode": false
  });
});