define("discourse/plugins/chat/discourse/components/chat/message-creator/lib/chatables-loader", ["exports", "@ember/owner", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/models/chat-chatable"], function (_exports, _owner, _service, _ajax, _ajaxError, _decorators, _chatChatable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_RESULTS = 10;
  class ChatablesLoader {
    static #_ = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loadingSlider", [_service.service]))();
    #loadingSlider = (() => (dt7948.i(this, "loadingSlider"), void 0))();
    constructor(context) {
      (0, _owner.setOwner)(this, (0, _owner.getOwner)(context));
    }
    async search(term) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        includeUsers: true,
        includeGroups: true,
        includeCategoryChannels: true,
        includeDirectMessageChannels: true,
        excludedUserIds: null,
        preloadChannels: false
      };
      this.request?.abort();
      if (!term && options.preloadChannels) {
        return this.#preloadedChannels();
      }
      if (!term) {
        return [];
      }
      try {
        this.loadingSlider.transitionStarted();
        this.request = (0, _ajax.ajax)("/chat/api/chatables", {
          data: {
            term,
            include_users: options.includeUsers,
            include_category_channels: options.includeCategoryChannels,
            include_direct_message_channels: options.includeDirectMessageChannels,
            excluded_memberships_channel_id: options.excludedMembershipsChannelId
          }
        });
        const results = await this.request;
        this.loadingSlider.transitionEnded();
        return [...results.users, ...results.groups, ...results.direct_message_channels, ...results.category_channels].map(item => {
          const chatable = _chatChatable.default.create(item);
          chatable.tracking = this.#injectTracking(chatable);
          return chatable;
        }).slice(0, MAX_RESULTS);
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "search", [_decorators.bind]))();
    #preloadedChannels() {
      return this.chatChannelsManager.allChannels.map(channel => {
        let chatable;
        if (channel.chatable?.users?.length === 1) {
          chatable = _chatChatable.default.createUser(channel.chatable.users[0]);
          chatable.tracking = this.#injectTracking(chatable);
        } else {
          chatable = _chatChatable.default.createChannel(channel);
          chatable.tracking = channel.tracking;
        }
        return chatable;
      }).filter(Boolean).slice(0, MAX_RESULTS);
    }
    #injectTracking(chatable) {
      if (!chatable.type === "channel") {
        return;
      }
      return this.chatChannelsManager.allChannels.find(channel => channel.id === chatable.model.id)?.tracking;
    }
  }
  _exports.default = ChatablesLoader;
});