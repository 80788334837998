define("discourse/plugins/chat/discourse/components/chat/navbar/threads-list-button", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _concatClass, _dIcon, _discourseI18n, _headerUnreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarThreadsListButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    threadsListLabel = (() => _discourseI18n.default.t("chat.threads.list"))();
    get showThreadsListButton() {
      return this.args.channel?.threadingEnabled && this.router.currentRoute.name !== "chat.channel.threads" && this.router.currentRoute.name !== "chat.channel.thread" && this.router.currentRoute.name !== "chat.channel.thread.index";
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showThreadsListButton}}
          <LinkTo
            @route="chat.channel.threads"
            @models={{@channel.routeModels}}
            title={{this.threadsListLabel}}
            class={{concatClass
              "c-navbar__threads-list-button"
              "btn"
              "no-text"
              "btn-flat"
              (if @channel.threadsManager.unreadThreadCount "has-unreads")
            }}
          >
            {{icon "discourse-threads"}}
            <ThreadHeaderUnreadIndicator @channel={{@channel}} />
          </LinkTo>
        {{/if}}
      
    */
    {
      "id": "HrF2otwx",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showThreadsListButton\"]],[[[1,\"      \"],[8,[32,0],[[16,\"title\",[30,0,[\"threadsListLabel\"]]],[16,0,[28,[32,1],[\"c-navbar__threads-list-button\",\"btn\",\"no-text\",\"btn-flat\",[52,[30,1,[\"threadsManager\",\"unreadThreadCount\"]],\"has-unreads\"]],null]]],[[\"@route\",\"@models\"],[\"chat.channel.threads\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,2],[\"discourse-threads\"],null]],[1,\"\\n        \"],[8,[32,3],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/threads-list-button.js",
      "scope": () => [_routing.LinkTo, _concatClass.default, _dIcon.default, _headerUnreadIndicator.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarThreadsListButton;
});