define("discourse/plugins/chat/discourse/routes/admin-plugins-chat", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _object, _ajax, _discourse, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatRoute extends _discourse.default {
    model() {
      if (!this.currentUser?.admin) {
        return {
          model: null
        };
      }
      return (0, _ajax.ajax)("/admin/plugins/chat/hooks.json").then(model => {
        model.incoming_chat_webhooks = model.incoming_chat_webhooks.map(webhook => _object.default.create(webhook));
        model.chat_channels = model.chat_channels.map(channel => {
          return _chatChannel.default.create(channel);
        });
        return model;
      });
    }
  }
  _exports.default = AdminPluginsChatRoute;
});