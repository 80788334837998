define("discourse/plugins/chat/discourse/services/chat-channel-pane", ["exports", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelPane extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "reacting", [_tracking.tracked], function () {
      return false;
    }))();
    #reacting = (() => (dt7948.i(this, "reacting"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selectingMessages", [_tracking.tracked], function () {
      return false;
    }))();
    #selectingMessages = (() => (dt7948.i(this, "selectingMessages"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "lastSelectedMessage", [_tracking.tracked], function () {
      return null;
    }))();
    #lastSelectedMessage = (() => (dt7948.i(this, "lastSelectedMessage"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "sending", [_tracking.tracked], function () {
      return false;
    }))();
    #sending = (() => (dt7948.i(this, "sending"), void 0))();
    get channel() {
      return this.chat.activeChannel;
    }
    get selectedMessageIds() {
      return this.channel.messagesManager.selectedMessages.mapBy("id");
    }
    cancelSelecting() {
      this.selectingMessages = false;
      this.channel.messagesManager.clearSelectedMessages();
    }
    static #_6 = (() => dt7948.n(this.prototype, "cancelSelecting", [_object.action]))();
    onSelectMessage(message) {
      this.lastSelectedMessage = message;
      this.selectingMessages = true;
    }
    static #_7 = (() => dt7948.n(this.prototype, "onSelectMessage", [_object.action]))();
  }
  _exports.default = ChatChannelPane;
});