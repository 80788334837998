define("discourse/plugins/chat/discourse/components/styleguide/chat-composer-message-details", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/owner", "@ember/service", "discourse/plugins/chat/discourse/lib/fabricators", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _owner, _service, _fabricators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<ChatComposerMessageDetails>">
    <Styleguide::Component>
      <ChatComposerMessageDetails @message={{this.message}} />
    </Styleguide::Component>
  
    <Styleguide::Controls>
      <Styleguide::Controls::Row @name="Mode">
        {{#if this.message.editing}}
          <DButton @action={{this.toggleMode}} @translatedLabel="Reply" />
        {{else}}
          <DButton @action={{this.toggleMode}} @translatedLabel="Editing" />
        {{/if}}
      </Styleguide::Controls::Row>
    </Styleguide::Controls>
  </StyleguideExample>
  */
  {
    "id": "UdTTMEaX",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<ChatComposerMessageDetails>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@message\"],[[30,0,[\"message\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@name\"],[\"Mode\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"message\",\"editing\"]],[[[1,\"        \"],[8,[39,6],null,[[\"@action\",\"@translatedLabel\"],[[30,0,[\"toggleMode\"]],\"Reply\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,6],null,[[\"@action\",\"@translatedLabel\"],[[30,0,[\"toggleMode\"]],\"Editing\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/component\",\"chat-composer-message-details\",\"styleguide/controls\",\"styleguide/controls/row\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-composer-message-details.hbs",
    "isStrictMode": false
  });
  class ChatStyleguideChatComposerMessageDetails extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "session", [_service.service]))();
    #session = (() => (dt7948.i(this, "session"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "keyValueStore", [_service.service]))();
    #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get message() {
      return new _fabricators.default((0, _owner.getOwner)(this)).message({
        user: this.currentUser
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "message", [_tracking.cached]))();
    toggleMode() {
      if (this.message.editing) {
        this.message.editing = false;
        this.message.inReplyTo = new _fabricators.default((0, _owner.getOwner)(this)).message();
      } else {
        this.message.editing = true;
        this.message.inReplyTo = null;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleMode", [_object.action]))();
  }
  _exports.default = ChatStyleguideChatComposerMessageDetails;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatComposerMessageDetails);
});