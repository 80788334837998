define("discourse/plugins/chat/discourse/modifiers/chat/track-message", ["exports", "@ember/destroyable", "ember-modifier", "discourse-common/utils/decorators"], function (_exports, _destroyable, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatTrackMessage extends _emberModifier.default {
    didEnterViewport = null;
    didLeaveViewport = null;
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [didEnterViewport, didLeaveViewport] = _ref;
      this.didEnterViewport = didEnterViewport;
      this.didLeaveViewport = didLeaveViewport;
      this.intersectionObserver = new IntersectionObserver(this._intersectionObserverCallback, {
        root: document,
        threshold: 0
      });
      this.intersectionObserver.observe(element);
    }
    cleanup() {
      this.intersectionObserver?.disconnect();
    }
    _intersectionObserverCallback(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.didEnterViewport?.();
        } else {
          this.didLeaveViewport?.();
        }
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "_intersectionObserverCallback", [_decorators.bind]))();
  }
  _exports.default = ChatTrackMessage;
});