define("discourse/plugins/chat/discourse/routes/chat-browse-index", ["exports", "@ember/service", "discourse/lib/utilities", "discourse/routes/discourse"], function (_exports, _service, _utilities, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatBrowseIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel() {
      if (!this.siteSettings.enable_public_channels) {
        return this.router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
      }
    }
    activate() {
      this.chat.activeChannel = null;
    }
    afterModel() {
      this.router.replaceWith("chat.browse.open");
    }
  }
  _exports.default = ChatBrowseIndexRoute;
});