define("discourse/plugins/chat/discourse/services/chat-emoji-picker-manager", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/lib/helpers", "discourse-common/utils/decorators"], function (_exports, _tracking, _object, _runloop, _service, _rsvp, _ajax, _ajaxError, _environment, _helpers, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TRANSITION_TIME = (0, _environment.isTesting)() ? 0 : 125; // CSS transition time
  const DEFAULT_VISIBLE_SECTIONS = ["favorites", "smileys_&_emotion"];
  const DEFAULT_LAST_SECTION = "favorites";
  class ChatEmojiPickerManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "closing", [_tracking.tracked], function () {
      return false;
    }))();
    #closing = (() => (dt7948.i(this, "closing"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "picker", [_tracking.tracked], function () {
      return null;
    }))();
    #picker = (() => (dt7948.i(this, "picker"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "emojis", [_tracking.tracked], function () {
      return null;
    }))();
    #emojis = (() => (dt7948.i(this, "emojis"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "visibleSections", [_tracking.tracked], function () {
      return DEFAULT_VISIBLE_SECTIONS;
    }))();
    #visibleSections = (() => (dt7948.i(this, "visibleSections"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "lastVisibleSection", [_tracking.tracked], function () {
      return DEFAULT_LAST_SECTION;
    }))();
    #lastVisibleSection = (() => (dt7948.i(this, "lastVisibleSection"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "element", [_tracking.tracked], function () {
      return null;
    }))();
    #element = (() => (dt7948.i(this, "element"), void 0))();
    get sections() {
      return !this.loading && this.emojis ? Object.keys(this.emojis) : [];
    }
    closeExisting() {
      this.visibleSections = DEFAULT_VISIBLE_SECTIONS;
      this.lastVisibleSection = DEFAULT_LAST_SECTION;
      this.picker = null;
    }
    static #_9 = (() => dt7948.n(this.prototype, "closeExisting", [_decorators.bind]))();
    close() {
      this.closing = true;
      (0, _runloop.later)(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.visibleSections = DEFAULT_VISIBLE_SECTIONS;
        this.lastVisibleSection = DEFAULT_LAST_SECTION;
        this.closing = false;
        this.picker = null;
      }, TRANSITION_TIME);
    }
    static #_10 = (() => dt7948.n(this.prototype, "close", [_decorators.bind]))();
    addVisibleSections(sections) {
      this.visibleSections = (0, _helpers.makeArray)(this.visibleSections).concat((0, _helpers.makeArray)(sections)).uniq();
    }
    open(picker) {
      this.loadEmojis();
      if (this.picker) {
        if (this.picker.trigger === picker.trigger) {
          this.closeExisting();
        } else {
          this.closeExisting();
          this.picker = picker;
        }
      } else {
        this.picker = picker;
      }
    }
    loadEmojis() {
      if (this.emojis) {
        return _rsvp.Promise.resolve();
      }
      this.loading = true;
      return (0, _ajax.ajax)("/chat/emojis.json").then(emojis => {
        this.emojis = emojis;
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "loadEmojis", [_object.action]))();
  }
  _exports.default = ChatEmojiPickerManager;
});