define("discourse/plugins/chat/discourse/services/chat-guardian", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatGuardian extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    canEditChatChannel() {
      return this.canUseChat() && this.currentUser.staff;
    }
    canArchiveChannel(channel) {
      return this.canEditChatChannel() && this.siteSettings.chat_allow_archiving_channels && !channel.isArchived && !channel.isReadOnly;
    }
    canUseChat() {
      return this.currentUser?.has_chat_enabled && this.siteSettings?.chat_enabled;
    }
  }
  _exports.default = ChatGuardian;
});