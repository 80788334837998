define("discourse/plugins/chat/discourse/lib/chat-ios-hacks", ["exports", "@ember/runloop", "discourse/services/capabilities", "discourse-common/lib/later"], function (_exports, _runloop, _capabilities, _later) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stackingContextFix = stackingContextFix;
  // since -webkit-overflow-scrolling: touch can't be used anymore to disable momentum scrolling
  // we use different hacks to work around this
  // if you change any line in this method, make sure to test on iOS
  function stackingContextFix(scrollable, callback) {
    if (_capabilities.capabilities.isIOS) {
      scrollable.style.overflow = "hidden";
      scrollable.querySelectorAll(".chat-message-separator__text-container").forEach(container => container.style.zIndex = "1");
    }
    callback?.();
    if (_capabilities.capabilities.isIOS) {
      (0, _runloop.next)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          scrollable.style.overflow = "auto";
          (0, _later.default)(() => {
            if (!scrollable) {
              return;
            }
            scrollable.querySelectorAll(".chat-message-separator__text-container").forEach(container => container.style.zIndex = "2");
          }, 50);
        });
      });
    }
  }
});