define("discourse/plugins/chat/discourse/modifiers/chat/emoji-picker-scroll-listener", ["exports", "@ember/destroyable", "@ember/service", "ember-modifier"], function (_exports, _destroyable, _service, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmojiPickerScrollListener extends _emberModifier.default {
    static #_ = (() => dt7948.g(this.prototype, "emojiPickerScrollObserver", [_service.service]))();
    #emojiPickerScrollObserver = (() => (dt7948.i(this, "emojiPickerScrollObserver"), void 0))();
    element = null;
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element) {
      this.element = element;
      this.emojiPickerScrollObserver.observe(element);
    }
    cleanup() {
      this.emojiPickerScrollObserver.unobserve(this.element);
    }
  }
  _exports.default = EmojiPickerScrollListener;
});