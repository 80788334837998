define("discourse/plugins/chat/discourse/modifiers/chat/on-long-press", ["exports", "@ember/destroyable", "@ember/runloop", "@ember/service", "ember-modifier", "discourse-common/lib/later", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _service, _emberModifier, _later, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function cancelEvent(event) {
    event.stopPropagation();
  }
  class ChatOnLongPress extends _emberModifier.default {
    static #_ = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    get enabled() {
      return this.capabilities.touch && this.site.mobileView;
    }
    modify(element, _ref) {
      let [onLongPressStart, onLongPressEnd, onLongPressCancel] = _ref;
      if (!this.enabled) {
        return;
      }
      this.element = element;
      this.onLongPressStart = onLongPressStart || (() => {});
      this.onLongPressEnd = onLongPressEnd || (() => {});
      this.onLongPressCancel = onLongPressCancel || (() => {});
      this.element.addEventListener("touchstart", this.handleTouchStart, {
        passive: true
      });
    }
    onCancel() {
      (0, _runloop.cancel)(this.timeout);
      if (this.capabilities.touch) {
        this.element.removeEventListener("touchmove", this.onCancel, {
          passive: true
        });
        this.element.removeEventListener("touchend", this.onCancel);
        this.element.removeEventListener("touchcancel", this.onCancel);
      }
      this.onLongPressCancel(this.element);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onCancel", [_decorators.bind]))();
    handleTouchStart(event) {
      if (event.touches.length > 1) {
        this.onCancel();
        return;
      }
      this.onLongPressStart(this.element, event);
      this.element.addEventListener("touchmove", this.onCancel, {
        passive: true
      });
      this.element.addEventListener("touchend", this.onCancel);
      this.element.addEventListener("touchcancel", this.onCancel);
      this.timeout = (0, _later.default)(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.element.addEventListener("touchend", cancelEvent, {
          once: true,
          passive: true
        });
        this.onLongPressEnd(this.element, event);
      }, 400);
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleTouchStart", [_decorators.bind]))();
    cleanup() {
      if (!this.enabled) {
        return;
      }
      this.element.removeEventListener("touchstart", this.handleTouchStart, {
        passive: true
      });
      this.onCancel();
    }
  }
  _exports.default = ChatOnLongPress;
});