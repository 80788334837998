define("discourse/plugins/chat/discourse/components/chat-channel-chooser", ["exports", "@ember-decorators/component", "select-kit/components/combo-box", "select-kit/components/select-kit"], function (_exports, _component, _comboBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatChannelChooser = dt7948.c(class ChatChannelChooser extends _comboBox.default {
    modifyComponentForRow() {
      return "chat-channel-chooser-row";
    }
  }, [(0, _selectKit.pluginApiIdentifiers)("chat-channel-chooser"), (0, _selectKit.selectKitOptions)({
    headerComponent: "chat-channel-chooser-header"
  }), (0, _component.classNames)("chat-channel-chooser")]);
  var _default = _exports.default = ChatChannelChooser;
});